import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import ContactSection from "../components/contactSection"
import MailingList from "../components/mailingList"
import Footer from "../components/footer"
import MessengerCustomerChat from "react-messenger-customer-chat"

import * as contactStyles from "./contact.module.css"

export default function Contact() {
  const facebookAppId = process.env.MESSENGER_API

  return (
    <div className={contactStyles.layout}>
      <SEO title="Contact Us" />
      <Header />
      <MessengerCustomerChat
        shouldShowDialog={false}
        minimized={false}
        title="Facebook customer chat"
        pageId="1522996417952337"
        appId={facebookAppId}
        themeColor="#ff6644"
        language="en_GB"
      />
      <div className={contactStyles.container}>
        <ContactSection contactPage={true} />
      </div>
      <MailingList />
      <Footer />
    </div>
  )
}
